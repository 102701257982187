import './App.css';

const App = () => {
  return (
    <div>
      <h1>Magnifai Health</h1>
      <p>The care that we all deserve</p>
      <p>Coming soon</p>
    </div>
  );
};

export default App;
